<template>
  <div
    id="statistic_app_page"
    class="card"
  >
    <b-card-header>
      <ps-filters
        v-model="filterValues"
        :filter-settings="filterSettings"
        @search="search"
      />
    </b-card-header>
    <div class="table-responsive mb-0">
      <table
        role="table"
        class="table b-table table-striped table-hover"
      >
        <thead
          role="rowgroup"
          class=""
        >
          <tr
            role="row"
            class=""
          >
            <th
              v-if="groupBy === 'app'"
              role="columnheader"
              aria-colindex="1"
            >
              App
            </th>
            <th
              v-if="groupBy === 'date'"
              role="columnheader"
              aria-colindex="1"
              :aria-sort="arialSort('date')"
              @click="sortCol('date')"
            >
              Date
            </th>
            <th
              v-if="groupBy === 'country'"
              role="columnheader"
              aria-colindex="1"
              :aria-sort="arialSort('country_code')"
              @click="sortCol('country_code')"
            >
              Country
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('unique_users')"
              @click="sortCol('unique_users')"
            >
              Unique Users
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('clicks')"
              @click="sortCol('clicks')"
            >
              Clicks
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('completes')"
              @click="sortCol('completes')"
            >
              Completes
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('completes_revenue')"
              @click="sortCol('completes_revenue')"
            >
              Completes Revenue
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('screenout_revenue')"
              @click="sortCol('screenout_revenue')"
            >
              Screenout Revenue
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('total_revenue')"
              @click="sortCol('total_revenue')"
            >
              Total Revenue
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <template v-for="(rowTr, indextr) in items">
            <tr
              :key="indextr"
              :class="rowTr.class"
            >
              <td v-if="groupBy === 'app'">
                <router-link :to="{ name: 'app-edit', params: { appHash: rowTr.app_hash }}">
                  {{ rowTr.app_name }}
                </router-link>
              </td>
              <td v-if="groupBy === 'date'">
                {{ rowTr.date | dotDateTimeShort }}
              </td>
              <td v-if="groupBy === 'country'">
                {{ rowTr.country_name }}
              </td>
              <td>
                {{ rowTr.unique_users | formatCount }}
              </td>
              <td>
                {{ rowTr.clicks_count | formatCount }}
              </td>
              <td>
                {{ rowTr.completes_count | formatCount }}
              </td>
              <td class="text-success">
                {{ rowTr.completes_revenue | formatCurrency }}
              </td>
              <td class="text-success">
                {{ rowTr.screenout_revenue | formatCurrency }}
              </td>
              <td class="text-success">
                {{ rowTr.total_revenue | formatCurrency }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <b-card-footer>
      <b-row>
        <b-col
          v-show="items.length > 0"
          cols="12"
        >
          <pagination-and-table-info
            :data="paginationData"
            @change="handleChangePage($event)"
          />
        </b-col>
        <b-col
          v-show="items.length === 0"
          cols="12"
          style="font-weight: 500; text-align: center"
        >
          No data
        </b-col>
      </b-row>
    </b-card-footer>
  </div>
</template>

<script>
import PaginationAndTableInfo from '@ps_main/custom-components/PaginationAndTableInfo.vue'
import PsFilters from '@/main/custom-components/PsFilters.vue'

export default {
  components: {
    PsFilters,
    PaginationAndTableInfo,
  },
  data() {
    const $this = this

    return {
      items: [],
      params: {
        page: 1,
        orderBy: 'date',
        itemPerPage: 500,
        orderDirection: 'desc',
        initFilter: 0,
      },
      filterSettings: {
        dateRange: {
          type: 'daterange',
          label: 'From - To',
          class: 'mr-2 mb-0',
          style: '',
          data: [],
          defaultValue: {
            fromDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            toDate: new Date(),
          },
          'label-cols': 2,
        },
        app: {
          type: 'select',
          label: 'App name',
          class: 'mr-2 mb-0',
          style: 'width: 320px;',
          data: [],
          defaultData: { value: null, text: 'All apps' },
          defaultValue: null,
          'label-cols': 4,
        },
        country: {
          type: 'select',
          label: 'country',
          class: 'mr-2 mb-0',
          style: 'width: 320px;',
          data: [],
          defaultData: { value: null, text: 'All countries' },
          defaultValue: null,
          'label-cols': 3,
        },
        group_by: {
          type: 'radio',
          label: 'Group by',
          class: 'mr-2',
          'label-cols': 2,
          'content-cols': 10,
          style: 'width: 400px;',
          defaultValue: 'app',
          data: [
            {
              value: 'app',
              text: 'App',
            },
            {
              value: 'country',
              text: 'Country',
            },
            {
              value: 'date',
              text: 'Day',
            },
          ],
          change(value) {
            $this.selectedGroupBy = value
          },
          refresh_uuid: null,
        },
      },
      pageTotal: 0,
      totalItems: 0,
      responseData: null,
      selectedGroupBy: 'app',
      groupBy: 'app',
    }
  },
  computed: {
    paginationData() {
      return this.laravelPaginationPages(this.responseData)
    },
  },
  methods: {
    handleChangePage(page) {
      this.params.page = page
      this.search()
    },
    sortCol(fieldName) {
      if (this.params.orderBy === fieldName) {
        this.params.orderDirection = this.params.orderDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.params.orderBy = fieldName
        this.params.orderDirection = 'asc'
      }
      this.search()
    },
    arialSort(sortFieldName) {
      if (this.params.orderBy === sortFieldName) {
        return this.params.orderDirection === 'asc'
          ? 'ascending'
          : 'descending'
      }
      return 'none'
    },
    search() {
      // this.groupBy = this.params.filters.group_by
      this.showLoading()
      this.$http
        .get('/api/statistics/app/get', {
          params: {
            ...this.params,
            ...this.filterValues,
          },
        })
        .then(response => {
          this.hideLoading()
          this.groupBy = this.selectedGroupBy
          const responseData = response.data
          this.items = responseData.data
          this.responseData = responseData
          this.initiateFilters(responseData)
        })
        .catch(errors => {
          this.alertError(errors.response.data)
          this.hideLoading()
        })
    },
    initiateFilters(responseData) {
      if (
        this.params.initFilter === 0
          && typeof responseData.filterData !== 'undefined'
      ) {
        Object.keys(responseData.filterData).forEach(property => {
          if (typeof this.filterSettings[property] === 'object') {
            if (this.filterSettings[property].defaultData !== undefined) {
              this.filterSettings[property].data = [
                ...[this.filterSettings[property].defaultData],
                ...responseData.filterData[property],
              ]
            } else {
              this.filterSettings[property].data = responseData.filterData[property]
            }
          }
        })

        this.params.initFilter = 1
      }
    },
  },
}
</script>
